<template>
    <div style="width:100%">
        <div class="topBox">
            <div class="contentBox">
                <div class="logo">
                    <img :src="teacherInfoS.img" alt="">
                </div>
                <div class="name">
                    {{teacherInfoS.name}}
                </div>
                <div class="cardBox">
                    <div v-for="(item,index) in teacherInfoS.tag.split(',')" :key="index">
                        {{item}}
                    </div>
                </div>
                <div class="descBox">
                    {{teacherInfoS.introduce}}
                </div>
            </div>
        </div>
        <div class="middleBox">
            <CourseList v-if="courseList.length > 0" :courseList="courseList"
                        :loading="loading"></CourseList>
            <div v-else>
                <el-empty description="没有内容哦"></el-empty>
            </div>
        </div>
        <div class="pageBox">
            <el-pagination
                    background
                    layout="prev, pager, next"
                    :current-page="params.page"
                    @current-change="setPage"
                    :total="params.total"
                    :page-size="params.per_page"
                    v-if="params.total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
    import {teacherInfo, teacherIndex} from "../../Api";
    import CourseList from "@/components/CourseList";
    export default {
        name: "TeacherCourseIndex",
        components:{CourseList},
        data(){
            return{
                loading: true,
                teacherId: '',
                teacherInfoS: {},
                courseList: [],
                params:{
                    per_page: 10,
                    page:1,
                    total: 0
                }
            }
        },
        watch: {
            params: {
                handler: function () {
                    this.loading = true;
                    const params = {
                        id:this.teacherId,
                        per_page: this.params.per_page,
                        page: this.params.page
                    }
                    teacherIndex(params)
                        .then(res => {
                            this.loading = false;
                            if(res.status == 200){
                                this.params.total = res.data.total
                                this.courseList = res.data.data
                            }
                        });
                },
                deep: true,
            },
        },
        methods:{
            //  选定分页
            setPage(val) {
                this.params.page = val;
                //回到顶部
                cancelAnimationFrame(this.timer);
                const self = this;
                self.timer = requestAnimationFrame(function fn() {
                    const oTop =
                        document.body.scrollTop || document.documentElement.scrollTop;
                    if (oTop > 0) {
                        document.body.scrollTop = document.documentElement.scrollTop =
                            oTop - 100;
                        self.timer = requestAnimationFrame(fn);
                    } else {
                        cancelAnimationFrame(self.timer);
                    }
                });
            },
            getCourseList(){
                const params = {
                    id:this.teacherId,
                    per_page: this.params.per_page,
                    page: this.params.page
                }
                teacherIndex(params).then(res=>{
                    if(res.status == 200){
                        this.params.total = res.data.total
                        this.courseList = res.data.data
                    }
                })
            },
            getTeacherData(){
                const params = {
                    teacher_id:this.teacherId
                }
                teacherInfo(params).then(res=>{
                    if(res.status == 200){
                        this.teacherInfoS = res.data
                    }
                })
            }
        },
        created(){
            this.teacherId = this.$route.query.id;
            this.getTeacherData();
            this.getCourseList()
        },
    }
</script>

<style scoped>
    ::v-deep .is-animated {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
.topBox{
    width: 100%;
    height: 4.6rem;
    opacity: 1;
    background-image: url("../../assets/image/courseChange/teacherBg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
}
    .contentBox{
        width: 5.78rem;
        height: 3.74rem;
        position: absolute;
        left: 7.81rem;
        top: 0.5rem;
    }
    .logo{
        width: 1rem;
        height: 1rem;
        border-radius:50%;
        opacity: 1;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 4px 8px 0px rgba(0, 0, 0, 0.15), 0px 15px 15px 0px rgba(0, 0, 0, 0.13), 0px 33px 20px 0px rgba(0, 0, 0, 0.08), 0px 59px 23px 0px rgba(0, 0, 0, 0.02), 0px 92px 26px 0px rgba(0, 0, 0, 0);
    }
    .logo>img{
        width:100%;
        height:100%;
        border-radius: 50%
    }
    .name{
        height: 0.62rem;
        opacity: 1;
        /** 文本1 */
        font-size: 0.42rem;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 0.62rem;
        color: rgba(255, 255, 255, 1);
        margin-top:0.16rem
    }
    .cardBox{
        width: 5.78rem;
        height: 0.37rem;
        opacity: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .cardBox>div{
        text-align: center;
        vertical-align: center;
        border-radius: 0.18rem;
        padding: 0.04rem 0.12rem 0.04rem 0.12rem;
        border: 1px solid rgba(229, 229, 229, 1);
        color: rgba(229, 229, 229, 1);
        margin-right:0.06rem;
        font-size: 0.14rem;
        margin-top:0.16rem
    }
    .descBox{
        width: 5.6rem;
        opacity: 1;
        /** 文本1 */
        font-size: 0.16rem;
        font-weight: 500;
        letter-spacing: 0px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        margin-top: 0.24rem;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    .middleBox{
        width: 17.2rem;
        margin: auto;
        background: rgba(247, 246, 251, 1);
        padding-top: 0.38rem;
    }
.pageBox {
    width: 100%;
    text-align: center;
    margin-bottom: 0.38rem
}
</style>
