<!--素材列表组件-->
<template>
  <el-skeleton style="width:100%" :loading="loading" animated :count="15">
    <div slot="template">
      <el-skeleton-item variant="image" style="width: 3.2rem; height: 2.84rem;margin-bottom: 0.4rem"/>
    </div>
    <div class="courseList" v-if="courseList.length > 0">
      <router-link :to="{path:'/course/detail',query:{detailId:item.id}}" target="_blank" class="courseItem" v-for="(item,index) in courseList" :key="index">
        <div class="vipLogo" v-if="item.is_vip === 1">VIP免费</div>
        <div class="courseItemImg">
          <img :src="item.img" alt="">
        </div>
        <div class="courseItemTitleBox">
          <div class="courseItemTitle txt-over">{{item.title}}</div>
          <div class="df_Yc courseItemTitleIcon">
            <!-- <div class="courseTitle-tag">{{ item.originalAttr }}</div> -->
            <p>{{item.sections}}节 | {{item.attr}}</p>
            <div class="titleIcon">
              <!--              <i class="iconfont">&#xe628;</i>-->
              <span>时长: {{ item.time_long }}</span>
            </div>
          </div>
          <div class="line"></div>
          <div class="courseItemBottomDesc">
            <div class="img">
              <img :src="item.author_avatar" alt="">
            </div>
            <div class="descBox">
              <div class="name">{{item.author_name}}</div>
              <div class="desc">{{item.author_introduce}}</div>
            </div>
          </div>
        </div>
      </router-link>
      <div style="height: 0;width: 3.2rem"></div>
      <div style="height: 0;width: 3.2rem"></div>
      <div style="height: 0;width: 3.2rem"></div>
    </div>
    <div v-else>
     <el-empty description="没有内容哦"></el-empty>
    </div>
  </el-skeleton>
</template>

<script>
export default {
  name: "CourseList",
  props:{
    courseList:{
      type:Array,
      default: () => []
    },
    loading:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{

    }
  },
}
</script>

<style scoped>
.el-empty{
	width:100%
}
  .titleIcon {
    font-size: 0.14rem;
    color: #999999;
  }

  .courseTitle-tag {
    background: #E94040;
    color: #FFFFFF;
    font-size: 0.12rem;
    padding: 0.01rem 0.05rem;
    border-radius: 0.02rem;
  }

  .courseItemTitleIcon {
    margin-top: 0.16rem;
    font-size: 0.12rem;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 0px;
    color: rgba(128, 128, 128, 1);
    justify-content: space-between;
  }

  .courseItemTitleBox {
    padding: 0.16rem 0.1rem 0.2rem 0.1rem;
  }
  .courseItemTitleBox > .courseItemBottomDesc{
    /*border:1px solid red;*/
    margin-top:0.18rem;
    display: flex;
  }
  .courseItemTitleBox > .courseItemBottomDesc>.img{
    width:0.4rem;height:0.4rem;border-radius:50%;
    margin-right:0.08rem
  }
  .courseItemTitleBox > .courseItemBottomDesc>.img>img{
    width:100%;height:100%;border-radius:50%
  }
  .courseItemTitleBox > .courseItemBottomDesc .name{
    font-size: 0.14rem;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(44, 44, 52, 1);
    height: 0.21rem;
    line-height: 0.21rem;
  }
  .courseItemTitleBox > .courseItemBottomDesc .desc{
    font-size: 0.12rem;
    font-weight: 400;
    color: rgba(128, 128, 128, 1);
    width: 2.32rem;
    white-space:nowrap;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
    overflow: hidden;
  }
  .courseItemTitle {
    width: 100%;
    color: #22242E;
    font-size: 0.16rem;
    font-weight: 600;
  }

  .courseItem{
    width: 3.2rem;
    width: 19%;
    margin-bottom: 0.4rem;
    background: #FFFFFF;
    border-radius: 0.08rem;
    overflow: hidden;
    box-shadow: 0px 0px 0.08rem rgba(153, 153, 153, 0.16);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    cursor: pointer;
    position: relative;
  }
  .courseItem>.teacherImg{
    width:100px;
    height:100px;
    border-radius:50%;
    margin: auto;
    margin-top:0.32rem
  }
  .teacherDesc{
    width: 2.56rem;
    height: 1.18rem;
    opacity: 1;
    font-size: 0.14rem;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 0px;
    color: rgba(56, 56, 56, 1);
    text-align: center;
    vertical-align: top;
    margin:auto;
    margin-top:0.16rem
  }
  .teacherName{
    width: 0.54rem;
    height: 0.27rem;
    opacity: 1;
    font-size: 0.18rem;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 0px;
    color: rgba(26, 43, 61, 1);
    text-align: center;
    vertical-align: top;
    margin:auto;
    margin-top:0.16rem
  }
  .courseItem>.teacherImg>img{
    width:100%;
    height:100%;
    border-radius:50%;
  }
  /* .courseItem:not(:nth-child(5n+0)){
    margin-right: 0.2rem;
  } */
  .courseItem:hover{
    transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    box-shadow: 0 26px 40px -24px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0 26px 40px -24px rgba(0,0,0,0.3);
    -moz-box-shadow: 0 26px 40px -24px rgba(0,0,0,0.3);
  }

  .courseItemImg {
    /* width: 3.2rem; */
    height: 1.92rem;
    overflow: hidden;
  }
  .courseItemImg img{
    width: 100%;
  }

  ::v-deep .courseList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .popContainer{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    font-size: 0.32rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 3.2rem;
  }
  .vipLogo{
    width: 0.76rem;
    height: 0.32rem;
    text-align: center;
    line-height: 0.32rem;
    opacity: 1;
    font-size: 0.12rem;
    font-weight: 700;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    background: rgba(255, 141, 26, 1);
    border-top-right-radius: 0.08rem;
    right:0px;
    border-bottom-left-radius: 0.08rem;
  }
  .line{
    border: 1px solid rgba(250, 250, 250, 1);
    margin-top:0.32rem
  }
</style>
